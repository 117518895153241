<script lang="ts">
  import Text from '../text/text.svelte';
  import Button from '../button/button.svelte';
  import Icon from '../icon/icon.svelte';

  type CookieConsentState = 'accepted' | 'rejected' | 'undecided';

  export let setCookieConsentState: (state: CookieConsentState) => void;
</script>

<div
  class="fixed bottom-0 left-0 right-0 z-10 flex flex-col bg-gradient-ultraviolet px-4 md:px-12 py-6 lg:flex-row border-t-4 border-space-black"
>
  <div
    class="flex flex-col sm:flex-row items-center justify-center mx-auto gap-6"
  >
    <div class="flex items-center gap-6">
      <Icon width={40} height={40} class="shrink-0" name="cookie" />
      <div class="flex flex-col gap-0">
        <Text variant="mono-16">View Our Cookie Policy</Text>
        <Text variant="body-16">
          We use cookies and similar technologies to help personalize content,
          tailor and measure ads, and provide a better experience. By clicking
          Accept, you agree to this as outlined in our
          <a
            href="https://docs.temporal.io/privacy-policy/"
            target="_blank"
            class="pulsar-link"
            rel="noreferrer"
          >
            cookie policy
          </a>.
        </Text>
      </div>
    </div>
    <div class="flex md:flex-row items-center sm:justify-end gap-4">
      <Button
        variant="primary"
        theme="green"
        on:click={() => {
          setCookieConsentState('accepted');
        }}>Accept</Button
      >
      <Button
        variant="ghost"
        on:click={() => {
          setCookieConsentState('rejected');
        }}>Decline</Button
      >
    </div>
  </div>
</div>
