<script lang="ts" context="module">
  type WindowWithDataLayer = Window & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[];
  };

  declare const window: WindowWithDataLayer;
</script>

<script lang="ts">
  import '../app.css';

  import CookieModal from '$lib/components/cookie-modal/cookie-modal.svelte';
  import { onMount } from 'svelte';
  import { safeLocalStorage } from '$lib/utilities/local-storage';

  export let cookieConsentState: CookieConsentState | null = null;
  type CookieConsentState = 'accepted' | 'rejected' | 'undecided';

  export let setCookieConsentState = (state: CookieConsentState) => {
    cookieConsentState = state;
    safeLocalStorage.setItem('cookie-consent', state);
    if (cookieConsentState === 'accepted') {
      createScript();
    }
  };

  const createScript = () => {
    const s = document.createElement('script');
    s.async = true;
    s.src = `https://www.googletagmanager.com/gtm.js?id=${
      import.meta.env.VITE_GMT_SECRET
    }`;
    window.dataLayer = window.dataLayer || [];

    function gtag(_config: string, _arg: unknown) {
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', import.meta.env.VITE_GMT_SECRET);

    document.head.append(s);
  };

  onMount(async () => {
    const cookieConsent = safeLocalStorage.getItem('cookie-consent');
    if (cookieConsent === 'rejected') {
      cookieConsentState = cookieConsent;
    } else if (cookieConsent === 'accepted') {
      createScript();
    } else {
      cookieConsentState = 'undecided';
    }
  });
</script>

<slot />
{#if cookieConsentState === 'undecided'}
  <CookieModal {setCookieConsentState} />
{/if}
